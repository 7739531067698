<template>
  <v-form>
    <v-row>
      <v-col cols="4">
        <v-text-field
          label="Customer Code"
          v-model="record.CardCode"
          :append-icon="'mdi-airballoon'"
          @click:append="openBp"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Customer Name"
          v-model="record.CardName"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Contact Person"
          v-model="record.Cntct"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Customer RefNo."
          v-model="record.NumAtCard"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          v-model="record.CurSource"
          :items="curSources"
          item-text="name"
          item-value="value"
          label="Currency Source"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="No."
          v-model="record.DocNum"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Status"
          v-model="Status"
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setPostingDate"
          :myDate="record.DocDate"
          :title="`Posting Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setDeliveryDate"
          :myDate="record.DocDueDate"
          :title="`Delivery Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setDocumentDate"
          :myDate="record.TaxDate"
          :title="`Document Date`"
        ></DatePicker>
      </v-col>

      <!-- tabs start here -->
      <v-tabs
        background-color="accent"
        color="white"
      >
        <v-tab>Contents</v-tab>
        <v-tab>Accounting</v-tab>
        <v-tab>Attachments</v-tab>
        <!-- v-tab-items -->

        <!-- start of contents -->
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <!-- new item activator  -->
              <new-item-modal
                :selectedTenant="selectedTenant"
                @itemData="addItemData"
              ></new-item-modal>
              <!-- end -->
            </v-col>
            <v-col
              cols="12"
              style="overflow-x: auto; display: flex; min-width: 100%"
            >
              <!-- start of items table -->
              <items-table :saleItemsData="record.dln1"></items-table>
              <!-- end of items table -->
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of contents -->

        <!-- start of accounting -->
        <v-tab-item>
          <v-row
            dense
            class="pa-3"
          >
            <v-col cols="4">
              <v-text-field
                label="Federal Tax Id"
                v-model="record.LicTradeNum"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                label="Blanket Agreement"
                v-model="record.AgrNo"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of accounting -->

        <!-- start of attachments -->
        <v-tab-item>
          <v-row
            dense
            class="pa-3"
          >Attachments</v-row>
        </v-tab-item>
        <!-- end of attachments -->
      </v-tabs>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              v-model="record.SlpCode"
              :items="employees"
              item-text="SlpName"
              item-value="id"
              label="Sales Employee"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-text-field
          label="Total Before Discount"
          v-model="totalPrice"
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field label="Discount %"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Discount Amount"
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Total Down Payment"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Tax"
          v-model="totalTax"
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Total"
          v-model="docTotal"
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="10">
        <v-btn
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>

      <v-col cols="2">
        <!-- <v-spacer></v-spacer>
        <v-btn color="primary" @click="openDraftDialog" class="right">
          <v-icon left>mdi-content-save</v-icon>Save As Draft
        </v-btn> -->
      </v-col>
    </v-row>

    <!-- start of bp fullscreen modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="bpDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Select Customer
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="bpDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clicked"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :search="search"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end -->

    <!-- draft confirm dialog -->
    <v-dialog
      v-model="draftDialog"
      width="600px"
    >
      <v-toolbar
        color="primary"
        dense
        dark
      >
        <v-toolbar-title>Draft Confirmation</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="draftDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row class="pa-3">
            <h2>Are you sure you want to save this document as Draft?</h2>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="accent"
                @click="sendDraftData"
                :right="trueValue"
              >Yes</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of draft dialog -->
  </v-form>
</template>


<script>
import NewItemModal from "./item-dialog.vue";
import ItemsTable from "./items-table.vue";
export default {
  components: {
    NewItemModal,
    ItemsTable,
  },
  props: {
    tenantData: {
      type: Object,
    },
    initial: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Array,
    },
  },
  computed: {
    saleItemsData() {
      return this.record.dln1;
    },
    docTotal() {
      let total = 0;
      if (
        typeof this.saleItemsData != "undefined" &&
        this.saleItemsData.length > 0
      ) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.GTotal;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    totalPrice() {
      let total = 0;
      if (
        typeof this.saleItemsData != "undefined" &&
        this.saleItemsData.length > 0
      ) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.LineTotal;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    totalTax() {
      let total = 0;
      if (
        typeof this.saleItemsData != "undefined" &&
        this.saleItemsData.length > 0
      ) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.VatSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
  },
  data: () => ({
    search: null,
    singleSelect: true,
    Status: "open",
    selected: [],
    selectedItems: [],
    selectedTenant: {},
    record: { parent: false, dln1: [] },
    itemsPerPage: 20,
    SlpCode: null,
    loading: false,
    draftDialog: false,
    bpDialog: false,
    itemDialog: false,
    trueValue: true,
    tab: null,
    date: null,
    DocDate: null,
    TaxDate: null,
    DocDueDate: null,
    priceList: [],
    salesEmployees: [],
    employees: [],
    bpMasterData: [],
    agreementTypes: [
      { name: "General", value: "G" },
      { name: "Specific", value: "S" },
    ],
    curSources: [
      { name: "Local Currency", value: "L" },
      { name: "System Currency", value: "S" },
      { name: "Bp Currency", value: "C" },
    ],
    agreementMethods: [
      { name: "Items Method", value: "I" },
      { name: "Monetary Method", value: "M" },
    ],
    status: [
      { name: "Approved", value: "A" },
      { name: "Draft", value: "D" },
    ],
    itemGroups: [],
    series: [{ name: "Manual", value: "M" }],
    itemTypes: [
      { name: "Fixed Assets", value: "F" },
      { name: "Items", value: "I" },
      { name: "Labour", value: "L" },
      { name: "Travel", value: "T" },
      { name: "Building", value: "B" },
    ],
    bpHeaders: [
      { text: "Code", value: "CardCode" },
      { text: "Name", value: "CardName" },
      { text: "Foreign Name", value: "CardFName" },
      { text: "Group", value: "GroupCode" },
    ],
    groups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    tenantData: {
      handler: "setTenant",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setTenant(val) {
      this.selectedTenant = { ...val };
    },
    openDraftDialog() {
      this.draftDialog = true;
    },
    addItemData(data) {
      this.record.dln1.push(data);
    },
    clicked($event) {
      this.selectedTenant = $event.item;
      const tenant = $event.item;
      this.record.CardCode = tenant.id;
      this.record.CardName = tenant.CardName;
      this.bpDialog = false;
    },
    setPostingDate(date) {
      this.record.DocDate = date;
    },
    setDeliveryDate(date) {
      this.record.DocDueDate = date;
    },
    setDocumentDate(date) {
      this.record.TaxDate = date;
    },
    sendData() {
      this.record.DocTotal = this.docTotal;
      this.record.VatSum = this.totalTax;
      this.record.dln1 = this.saleItemsData;
      this.$emit("data", this.record);
    },
    sendDraftData() {
      this.record.DocTotal = this.docTotal;
      this.record.VatSum = this.totalTax;
      this.record.ObjType = 13;
      this.record.dln1 = this.saleItemsData;
      this.$emit("draftData", this.record);
    },
    openBp() {
      this.selected = [];
      this.bpDialog = true;
    },
    getItemGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.employees = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setDefaults() {
      // date defaults
      const date = new Date().toISOString().substr(0, 10);
      this.record.DocDate = date;
      this.record.TaxDate = date;
      // Currency source
      this.record.CurSource = "L";
    },
  },
  created() {
    this.setDefaults();
    this.getItemGroups();
    this.getBpMaster();
    this.getEmployees();
  },
};
</script>

